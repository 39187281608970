@import '../../../node_modules/normalize-scss/sass/normalize/import-now';

$redColor: #aa0000;
$redColorBrighter: #bb0000;

@font-face {
  font-family: body;
  src: url('./fonts/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: body;
  src: url('./fonts/OpenSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: title;
  src: url('./fonts/Unquiet\ Spirits.ttf');
}

div.modal {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

h1 {
  font-family: title;
  font-size: min(6em, 24vw);
  margin: 0;
}

a {
  color: #0099ee;
  text-decoration: none;
  &:hover {
    color: #00aaff;
  }
}

body {
  font-family: body;
  background-color: #000;
  color: $redColor;
  font-size: 1.4rem;
}

p.faded {
  opacity: 0.8;
}

.fadein {
  animation-name: fadein;
  animation-duration: 4s;
  animation-delay: 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.oneLine {
  display: inline-block;
}

div.loadingBar {
  height: 2px;
  background-color: $redColor;
  animation-name: loadingBar;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes loadingBar {
  from {
    width: 0px;
    margin-right: 200px;
    margin-left: 0px;
  }
  50% {
    width: 200px;
    margin-right: 0px;
    margin-left: 0px;
  }
  to {
    width: 0px;
    margin-left: 200px;
    margin-right: 0px;
  }
}

div.button {
  cursor: pointer;
  padding: 0.6rem 1rem;
  display: inline-block;
  border-radius: 1.2rem;
  color: $redColor;
  font-size: 1.2rem;
  outline: $redColor solid 2px;
  margin: 2px;
  &:hover {
    color: $redColorBrighter;
    outline: $redColorBrighter solid 2px;
  }
  &.primary {
    background-color: $redColor;
    color: black;
    margin: 0px;
    &:hover {
      background-color: $redColorBrighter;
    }
  }
}
img.button {
  vertical-align: middle;
  margin-left: 5px;
}

input[type='text'] {
  font-family: body;
  display: inline-block;
  background-color: black;
  border: none;
  outline: $redColor solid 2px;
  color: $redColor;
  width: 150px;
  &:focus {
    outline: $redColorBrighter solid 2px;
    color: $redColorBrighter;
  }
  user-select: auto;
  &::selection {
    color: black;
    background-color: $redColorBrighter;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  &:focus {
    outline: none;
  }
}
